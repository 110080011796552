<!-- POPUP SAC -->
<app-sac
  *ngIf="myHunt"
  [image]="myHunt.img_empty_eco"
  [title]="myHunt.title_eco"
  [clue]="idclues"
></app-sac>
<!-- FIN POPUP SAC -->

<!-- POPUP D'INDICE -->
<div *ngIf="myHunt">
  <div *ngIf="showIndice == true" class="bag-view indice-modal">
    <div class="modal-back" (click)="hideIndice()"></div>
    <div class="bag-content">
      <i class="fas fa-times" (click)="hideIndice()"></i>
      <h2 class="txt-success">{{ myHunt.title_indice }}</h2>
      <p
        class="indice-text"
        [innerHTML]="HuntService.chasse.clues[idclues].help[0].text"
      ></p>
    </div>
  </div>
</div>
<!-- FIN POPUP D'INDICE'-->

<!-- DEBUT POPUP PARAMS-->
<div *ngIf="myHunt">
  <div *ngIf="showParams == true" class="bag-view indice-modal params">
    <div class="modal-back" (click)="hideParams()"></div>
    <div class="bag-content">
      <i class="fas fa-times" (click)="hideParams()"></i>
      <h2>{{ HuntService.chasse.title_params }}</h2>
      <p class="txt-params" [innerHTML]="HuntService.chasse.text_params"></p>
      <p
        class="txt-params"
        [innerHTML]="HuntService.chasse.title_btn_params"
      ></p>
      <button class="button-totemus" (click)="endHunt()">
        {{ HuntService.chasse.button_params }}
      </button>
    </div>
  </div>
</div>
<!-- FIN POPUP PARAMS-->
<!-- DEBUT POPUP STILLPLAY s'active apres l'innactivité du joueurs . durée a definir-->
<div *ngIf="myHunt">
  <div *ngIf="stillPlay == true" class="bag-view indice-modal params">
    <div class="modal-back" (click)="hideStillPlay()"></div>
    <div class="bag-content">
      <i class="fas fa-times" (click)="hideStillPlay()"></i>
      <h2>{{ myHunt.title_continue }}</h2>
      <p class="txt-params" [innerHTML]="myHunt.text_continue"></p>
      <!-- <p class="txt-params" [innerHTML]="myHunt.title_btn_params"></p> -->
      <button class="button-totemus" (click)="hideStillPlay()">
        {{ myHunt.btn_continue }}
      </button>
      <button class="button-totemus" (click)="endHunt()">
        {{ myHunt.btn_remake }}
      </button>
    </div>
  </div>
</div>
<!-- FIN POPUP STILLPLAY-->

<div
  class="chasse"
  *ngIf="myHunt && HuntService.utilisateur.endChasse == false"
>
  <div class="header-chasse">
    <div class="group-icons">
      <div class="arrows">
        <button
          [disabled]="showRules == true || idclues > 0 ? false : true"
          (click)="previousStep()"
          class="rightArrow"
        >
          <img src="assets/img/left-arrow2.svg" alt="Fleche gauche" />
        </button>
        <button
          [disabled]="
            idclues + 1 == myHunt.clues.length ||
            idclues >= HuntService.utilisateur.position
              ? true
              : false
          "
          (click)="nextStep()"
          class="rightArrow"
        >
          <img src="assets/img/right-arrow.svg" alt="Fleche droite" />
        </button>
      </div>
      <div class="backpack">
        <button class="icon-bagpack" (click)="showBagFunction()">
          <img src="assets/img/backpack-dark.svg" alt="Sac à dos" /><span
            *ngIf="HuntService.utilisateur.sac.length > 0"
            class="nb-objet"
            >{{ HuntService.utilisateur.sac.length }}</span
          >
        </button>
        <button class="btn-params" (click)="showParamsFunction()">
          <img src="assets/img/dots-new.svg" alt="paramétres" />
        </button>
      </div>
    </div>
    <div class="progress">
      <span
        class="theBar"
        [ngStyle]="{ width: widthBar(HuntService.chasse.clues) + '%' }"
      >
      </span>
    </div>
  </div>
  <div class="body-chasse">
    <div class="steps">
      <span
        >étape {{ idclues }} sur {{ HuntService.chasse.clues.length - 1 }}
      </span>
    </div>

    <!-- test document -->
    <!-- <p class="txt-secondary">Remettez ce document dans l'ordre</p>
            <div class="containGame" cdkScrollable>
                <img cdkDrag  *ngIf="docGameSolved==false" id="doc1" cdkDragBoundary=".containGame"  (cdkDragEnded)="testdocument3($event)" src="../../assets/img/3doc.svg" alt="dechet">
                <img cdkDrag  *ngIf="docGameSolved==false" id="doc2" cdkDragBoundary=".containGame" (cdkDragEnded)="testdocument6($event)" src="../../assets/img/6doc.svg" alt="dechet">
                <img cdkDrag  *ngIf="docGameSolved==false" id="doc3" cdkDragBoundary=".containGame" (cdkDragEnded)="testdocument2($event)" src="../../assets/img/2doc.svg" alt="dechet">
                <img cdkDrag  *ngIf="docGameSolved==false" id="doc4" cdkDragBoundary=".containGame" (cdkDragEnded)="testdocument($event)" src="../../assets/img/1doc.svg" alt="dechet">
                <img cdkDrag  *ngIf="docGameSolved==false" id="doc5" cdkDragBoundary=".containGame" (cdkDragEnded)="testdocument5($event)" src="../../assets/img/5doc.svg" alt="dechet">
                <img cdkDrag  *ngIf="docGameSolved==false" id="doc6" cdkDragBoundary=".containGame" (cdkDragEnded)="testdocument4($event)" src="../../assets/img/4doc.svg" alt="dechet">
                <div class="documentbox" [ngClass]="{finished: docGameSolved==true}">
                </div>
                <button  *ngIf="docGameSolved==true" class="button-totemus" (click)="nextStep()">Prochaine étape</button>
            </div> -->
    <!-- FIN TEST -->

    <!-- IF TYPE QR-->
    <div
      *ngIf="
        HuntService.chasse.clues[idclues].type == 'qr' &&
        HuntService.utilisateur.position == idclues
      "
      class="chasse-qr"
    >
      <p
        class="txt-secondary"
        [innerHTML]="HuntService.chasse.clues[idclues].content"
      ></p>
      <p class="error-message" *ngIf="errorMessage ? true : false">
        {{ errorMessage }}
      </p>
      <button
        *ngIf="showQR == false"
        class="button-totemus"
        (click)="showCode()"
      >
        Scanner le QR code
      </button>
      <zxing-scanner
        class="scanner-QR"
        *ngIf="showQR == true"
        (scanSuccess)="onCodeResult($event, '0000')"
      ></zxing-scanner>
      <p
        class="indice"
        *ngIf="HuntService.chasse.clues[idclues].help[0]"
        [innerHTML]="HuntService.chasse.clues[idclues].help[0].label"
        (click)="showIndiceFunction()"
      ></p>
    </div>
    <div
      *ngIf="
        HuntService.chasse.clues[idclues].type == 'qr' &&
        HuntService.utilisateur.position > idclues
      "
    >
      <p class="txt-success">Vous avez scanné le bon code 🎉</p>
      <p
        class="txt-secondary"
        [innerHTML]="HuntService.chasse.clues[idclues].unlock_content"
      ></p>
      <div
        class="symbol-box"
        *ngIf="HuntService.chasse.clues[idclues].has_symbol == true"
        [style.backgroundColor]="HuntService.chasse.clues[idclues].symbol.color"
      >
        <img
          [src]="HuntService.chasse.clues[idclues].symbol.image.s160"
          alt="symbole débloqué"
        />
      </div>
      <button
        *ngIf="
          HuntService.utilisateur.position > idclues &&
          HuntService.utilisateur.position < HuntService.chasse.clues.length
        "
        class="button-totemus"
        (click)="nextStep()"
      >
        Prochaine étape
      </button>
    </div>
    <!-- END IF TYPE QR-->

    <!-- IF TYPE QUESTION -->
    <div *ngIf="myHunt.clues[idclues].type == 'code'" class="chasse-question">
      <p
        *ngIf="HuntService.utilisateur.position == idclues"
        class="txt-secondary"
        [innerHTML]="HuntService.chasse.clues[idclues].content"
      ></p>
      <input
        *ngIf="HuntService.utilisateur.position == idclues"
        type="text"
        class="reponse-txt"
        placeholder="votre réponse"
        [(ngModel)]="HuntService.utilisateur.reponseQuestion"
      />
      <p
        class="txt-secondary"
        *ngIf="HuntService.utilisateur.position > idclues"
        [innerHTML]="HuntService.chasse.clues[idclues].unlock_content"
      ></p>
      <div *ngIf="HuntService.utilisateur.position == idclues">
        <p class="error-message" *ngIf="errorMessage ? true : false">
          {{ errorMessage }}
        </p>
        <button
          class="button-totemus"
          [disabled]="HuntService.utilisateur.reponseQuestion ? false : true"
          (click)="
            checkCode(
              HuntService.utilisateur.reponseQuestion,
              HuntService.chasse.clues[idclues].code
            )
          "
        >
          je valide ma réponse
        </button>
        <p
          class="indice"
          *ngIf="myHunt.clues[idclues].help[0]"
          [innerHTML]="myHunt.clues[idclues].help[0].label"
          (click)="showIndiceFunction()"
        ></p>
      </div>
      <div
        class="symbol-box"
        *ngIf="
          HuntService.chasse.clues[idclues].has_symbol == true &&
          HuntService.utilisateur.position > idclues
        "
        [style.backgroundColor]="myHunt.clues[idclues].symbol.color"
      >
        <img
          [src]="HuntService.chasse.clues[idclues].symbol.image.s160"
          alt="symbole débloqué"
        />
      </div>
      <button
        *ngIf="HuntService.utilisateur.position > idclues"
        class="button-totemus"
        (click)="nextStep()"
      >
        Prochaine étape
      </button>
    </div>
    <!-- END IF TYPE QUESTION -->

    <!-- IF TYPE INFO-->
    <app-info
      *ngIf="HuntService.chasse.clues[idclues].type == 'info'"
      (rulesUpdate)="showRule($event)"
      (nextStepUpdate)="startPoint($event)"
      [showRules]="showRules"
      [idclues]="idclues"
      [myHunt]="HuntService.chasse"
    ></app-info>
    <!-- END IF TYPE INFO-->

    <!-- IF TYPE DATE-->
    <app-date
      *ngIf="HuntService.chasse.clues[idclues].type == 'date'"
      (dateUpdate)="isDateSolved($event)"
      (indiceUpdate)="isIndiceNeeded($event)"
      (nextStepUpdate)="isNextNeeded($event)"
      [idclues]="idclues"
      [myHunt]="HuntService.chasse"
    ></app-date>
    <!-- END IF TYPE DATE-->

    <!-- IF TYPE FOURCHETTE -->
    <app-fourchette
      *ngIf="HuntService.chasse.clues[idclues].type == 'fourchette'"
      (fourchetteUpdate)="isFourchetteSolved($event)"
      (indiceUpdate)="isIndiceNeeded($event)"
      (nextStepUpdate)="isNextNeeded($event)"
      [idclues]="idclues"
      [myHunt]="HuntService.chasse"
    ></app-fourchette>
    <!-- END IF TYPE FOURCHETTE -->

    <!-- IF TYPE AUDIO -->
    <app-audio
      *ngIf="HuntService.chasse.clues[idclues].type == 'audio'"
      (audioUpdate)="isAudioSolved($event)"
      (indiceUpdate)="isIndiceNeeded($event)"
      (nextStepUpdate)="isNextNeeded($event)"
      [idclues]="idclues"
      [myHunt]="HuntService.chasse"
    ></app-audio>
    <!-- END IF TYPE AUDIO -->

    <!-- IF TYPE QCM -->
    <app-qcm
      *ngIf="HuntService.chasse.clues[idclues].type == 'qcm'"
      (qcmUpdate)="isQcmSolved($event)"
      (indiceUpdate)="isIndiceNeeded($event)"
      (nextStepUpdate)="isNextNeeded($event)"
      [idclues]="idclues"
      [myHunt]="HuntService.chasse"
    ></app-qcm>
    <!-- END IF TYPE QCM -->

    <!-- IF DRAG DROP GAME -->
    <app-dragdrop
      *ngIf="HuntService.chasse.clues[idclues].type == 'drag'"
      (dragUpdate)="isDragSolved($event)"
      (nextStepUpdate)="isNextNeeded($event)"
      [idclues]="idclues"
      [myHunt]="HuntService.chasse"
    ></app-dragdrop>

    <!-- END IF DRAG DROP GAME -->

    <!-- IF TYPE PUZZLE -->
    <div *ngIf="HuntService.chasse.clues[idclues].type == 'puzzle'">
      <app-puzzle
        class="puzzle-zone"
        (puzzleUpdate)="isPuzzleSolved($event)"
        *ngIf="
          HuntService.chasse.clues[idclues].type == 'puzzle' &&
          HuntService.utilisateur.position == idclues
        "
        [background]="HuntService.chasse.clues[idclues].img_puzzle"
        [title]="HuntService.chasse.clues[idclues].content"
      ></app-puzzle>
      <div
        *ngIf="
          HuntService.chasse.clues[idclues].type == 'puzzle' &&
          HuntService.utilisateur.position == idclues
        "
      ></div>
      <p
        *ngIf="HuntService.utilisateur.position > idclues"
        class="txt-secondary"
        [innerHTML]="HuntService.chasse.clues[idclues].unlock_content"
      ></p>
      <div class="iframe-box">
        <!-- <iframe *ngIf="HuntService.utilisateur.position > idclues" class="juxtapose" frameborder="0" height="220" src="https://cdn.knightlab.com/libs/juxtapose/latest/embed/index.html?uid=40f38ade-7b92-11e9-8804-0edaf8f81e27" width="100%"></iframe> -->
        <!-- <iframe *ngIf="HuntService.utilisateur.position > idclues" frameborder="0" class="juxtapose" width="100%" height="250" src="https://cdn.knightlab.com/libs/juxtapose/latest/embed/index.html?uid=f2a5802a-cd20-11eb-b7bf-95443c729a29"></iframe> -->
      </div>
      <div
        class="symbol-box"
        *ngIf="
          HuntService.chasse.clues[idclues].has_symbol == true &&
          HuntService.utilisateur.position > idclues
        "
        [style.backgroundColor]="myHunt.clues[idclues].symbol.color"
      >
        <img
          [src]="HuntService.chasse.clues[idclues].symbol.image.s160"
          alt="symbole débloqué"
        />
      </div>
      <button
        *ngIf="HuntService.utilisateur.position > idclues"
        class="button-totemus"
        (click)="nextStep()"
      >
        Prochaine étape
      </button>
    </div>
    <!-- END IF TYPE PUZZLE -->
  </div>
</div>
<!-- END CHASSE -->
<div *ngIf="myHunt" class="chasse">
  <div
    class="body-chasse"
    *ngIf="HuntService.utilisateur.endChasse == true && submitEndForm == false"
  >
    <div *ngIf="showConcours == false" class="endGame">
      <!-- <div class="ecoBox">
                <img [src]="myHunt.img_full_eco" alt="" class="ecosysteme">
            </div> -->

      <p [innerHTML]="HuntService.chasse.text_congrats"></p>
      <p class="txt-secondary">
        Remettez ce document dans l'ordre afin de découvrir le rébus.
      </p>
      <div class="containGame" cdkScrollable>
        <img
          cdkDrag
          *ngIf="docGameSolved == false"
          id="doc1"
          cdkDragBoundary=".containGame"
          (cdkDragEnded)="testdocument3($event)"
          src="../../assets/img/3doc.svg"
          alt="dechet"
        />
        <img
          cdkDrag
          *ngIf="docGameSolved == false"
          id="doc2"
          cdkDragBoundary=".containGame"
          (cdkDragEnded)="testdocument6($event)"
          src="../../assets/img/6doc.svg"
          alt="dechet"
        />
        <img
          cdkDrag
          *ngIf="docGameSolved == false"
          id="doc3"
          cdkDragBoundary=".containGame"
          (cdkDragEnded)="testdocument2($event)"
          src="../../assets/img/2doc.svg"
          alt="dechet"
        />
        <img
          cdkDrag
          *ngIf="docGameSolved == false"
          id="doc4"
          cdkDragBoundary=".containGame"
          (cdkDragEnded)="testdocument($event)"
          src="../../assets/img/1doc.svg"
          alt="dechet"
        />
        <img
          cdkDrag
          *ngIf="docGameSolved == false"
          id="doc5"
          cdkDragBoundary=".containGame"
          (cdkDragEnded)="testdocument5($event)"
          src="../../assets/img/5doc.svg"
          alt="dechet"
        />
        <img
          cdkDrag
          *ngIf="docGameSolved == false"
          id="doc6"
          cdkDragBoundary=".containGame"
          (cdkDragEnded)="testdocument4($event)"
          src="../../assets/img/4doc.svg"
          alt="dechet"
        />
        <div
          class="documentbox"
          [ngClass]="{ finished: docGameSolved == true }"
        ></div>
        <input
          *ngIf="docGameSolved == true"
          type="text"
          class="reponse-txt reponse-rebus"
          placeholder="votre réponse"
          [(ngModel)]="HuntService.utilisateur.reponseQuestion"
        />
        <button
          *ngIf="docGameSolved == true"
          [disabled]="HuntService.utilisateur.reponseQuestion ? false : true"
          class="button-totemus"
          (click)="rebus(HuntService.utilisateur.reponseQuestion, 'pinceau')"
        >
          Valider votre réponse
        </button>
      </div>
      <!-- <button class="button-totemus" (click)="showConcours=true" >{{myHunt.button_participation_true}}</button> -->
      <!-- <a class="retourButton" (click)="submitEndForm=true">{{myHunt.button_participation_false}}</a> -->
    </div>
    <div *ngIf="showConcours == true">
      <div class="imgEndBox">
        <img class="award" src="../../assets/img/award.svg" alt="" />
        <img class="confetti" src="../../assets/img/confetti.svg" alt="" />
      </div>
      <h2 class="txt-success end">Félicitation</h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean vehicula
        felis turpis, ac blandit arcu malesuada pharetra. Morbi ut velit a dolor
        sagittis volutpat pellentesque ut ligula. Phasellus erat est, luctus
        eget sagittis sit amet, elementum id risus.
      </p>
      <button class="button-totemus" (click)="endHunt()">
        Revenir à l'écran d'accueil
      </button>

      <!-- <p class="txt-secondary questionSub" [innerHTML]="myHunt.question"></p> -->
      <!-- <form  class="finFormulaire">
            <input type="text" required name="subsidiaire" id="subsidiaire" placeholder="Réponse à la question subsidiaire" [(ngModel)]="this.bodyEnd.number_of_participants">
            <input type="number" inputmode="decimal" required name="code postal" id="" placeholder="Code postal" [(ngModel)]="this.bodyEnd.postcode">
            <input type="number" inputmode="decimal" required name="phone" id="phone" placeholder="Numéro de téléphone" [(ngModel)]="this.bodyEnd.phone">
            <input *ngIf="_iOSDevice==false"type="text" onfocus="(this.type='date')"  onblur="(this.type='text')" required name="birth" id="birth" placeholder="Date de naissance" [(ngModel)]="this.bodyEnd.birth_date">
            <input *ngIf="_iOSDevice==true"type="date" required name="birth" id="birth" placeholder="Date de naissance" [(ngModel)]="this.bodyEnd.birth_date" (click)="showIOSinput=false">
            <p *ngIf="_iOSDevice==true && showIOSinput==true"class="placeholder_iphone">Date de naissance</p>
            <a href=" https://www.mediacite.be/fr/CG-jeu-ecosysteme" target="_blank" class="end-rules">Voir le règlement du concours</a>
            <button class="button-totemus" [disabled]="bodyEnd.postcode && bodyEnd.number_of_participants && bodyEnd.phone && bodyEnd.birth_date ? false : true" (click)="endFormSubmit()" >{{myHunt.button_participation_send}}</button>
            <p (click)="showConcours=false" class="retourButton end-link">Retour</p>
        </form> -->
    </div>
  </div>
  <!-- <div class="body-chasse submitEndChasse" *ngIf="HuntService.utilisateur.endChasse == true && submitEndForm == true">
        <img [src]="myHunt.img_end_logo" alt="logo mediacite" class="logo-fin">
        <p [innerHTML]="myHunt.text_end" class="end-text"></p>
        <a class="txt-secondary end-link" (click)="endHunt()">Retour à l'accueil</a>
    </div> -->
  <!-- END END CHASSE -->
</div>
