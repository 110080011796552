<div *ngIf="myHunt.clues[idclues].type == 'audio'" class="chasse-audio">
  <p
    *ngIf="HuntService.utilisateur.position == idclues"
    class="txt-secondary"
    [innerHTML]="myHunt.clues[idclues].content"
  ></p>
  <input
    *ngIf="HuntService.utilisateur.position == idclues"
    type="text"
    class="reponse-txt"
    placeholder="Votre réponse"
    [(ngModel)]="HuntService.utilisateur.reponseQuestion"
  />
  <div *ngIf="HuntService.utilisateur.position == idclues">
    <p class="error-message" *ngIf="errorMessage ? true : false">
      {{ errorMessage }}
    </p>
    <button
      *ngIf="HuntService.utilisateur.position == idclues"
      class="button-totemus"
      [disabled]="HuntService.utilisateur.reponseQuestion ? false : true"
      (click)="
        checkSound(
          HuntService.utilisateur.reponseQuestion,
          myHunt.clues[idclues].code
        )
      "
    >
      je valide ma réponse
    </button>
    <p
      class="indice"
      *ngIf="myHunt.clues[idclues].help[0]"
      [innerHTML]="myHunt.clues[idclues].help[0].label"
      (click)="showIndice()"
    ></p>
    <!-- (click)="showIndiceFunction()" -->
  </div>
  <p
    *ngIf="HuntService.utilisateur.position > idclues"
    class="txt-secondary"
    [innerHTML]="HuntService.chasse.clues[idclues].unlock_content"
  ></p>

  <div
    class="symbol-box"
    *ngIf="
      myHunt.clues[idclues].has_symbol == true &&
      HuntService.utilisateur.position > idclues
    "
    [style.backgroundColor]="myHunt.clues[idclues].symbol.color"
  >
    <img
      [src]="myHunt.clues[idclues].symbol.image.s160"
      alt="symbole débloqué"
    />
  </div>
  <button
    *ngIf="HuntService.utilisateur.position > idclues"
    class="button-totemus"
    (click)="showNextStep()"
  >
    Prochaine étape
  </button>
  <!-- (click)="nextStep() -->
</div>
