<div class="dragdrop" *ngIf="myHunt.clues[idclues].type == 'drag'">
  <p
    *ngIf="HuntService.utilisateur.position == idclues"
    class="txt-secondary"
    [innerHTML]="myHunt.clues[idclues].content"
  ></p>
  <p
    *ngIf="HuntService.utilisateur.position > idclues"
    class="txt-secondary"
    [innerHTML]="HuntService.chasse.clues[idclues].unlock_content"
  ></p>

  <div
    class="symbol-box"
    *ngIf="
      myHunt.clues[idclues].has_symbol == true &&
      HuntService.utilisateur.position > idclues
    "
    [style.backgroundColor]="myHunt.clues[idclues].symbol.color"
  >
    <img
      [src]="myHunt.clues[idclues].symbol.image.s160"
      alt="symbole débloqué"
    />
  </div>
  <button
    *ngIf="HuntService.utilisateur.position > idclues"
    class="button-totemus"
    (click)="showNextStep()"
  >
    Prochaine étape
  </button>
  <div
    *ngIf="HuntService.utilisateur.position == idclues"
    class="tri-zone"
    cdkDragBoundary
  >
    <div class="dechet">
      <img
        [style.opacity]="dechetsTab[0]"
        cdkDrag
        cdkDragBoundary=".tri-zone"
        (cdkDragEnded)="
          onDragEnded($event, 0, myHunt.clues[idclues].response_trash1)
        "
        [src]="myHunt.clues[idclues].image_trash1"
        alt="dechet"
      />
      <img
        [style.opacity]="dechetsTab[1]"
        cdkDrag
        cdkDragBoundary=".tri-zone"
        (cdkDragEnded)="
          onDragEnded2($event, 1, myHunt.clues[idclues].response_trash2)
        "
        [src]="myHunt.clues[idclues].image_trash2"
        alt="dechet"
      />
      <img
        [style.opacity]="dechetsTab[2]"
        cdkDrag
        cdkDragBoundary=".tri-zone"
        (cdkDragEnded)="
          onDragEnded3($event, 2, myHunt.clues[idclues].response_trash3)
        "
        [src]="myHunt.clues[idclues].image_trash3"
        alt="dechet"
      />
      <img
        [style.opacity]="dechetsTab[3]"
        cdkDrag
        cdkDragBoundary=".tri-zone"
        (cdkDragEnded)="
          onDragEnded4($event, 3, myHunt.clues[idclues].response_trash4)
        "
        [src]="myHunt.clues[idclues].image_trash4"
        alt="dechet"
      />
    </div>
    <div class="poubelle">
      <div class="poub p1">{{ myHunt.clues[idclues].title_trash1 }}</div>
      <div class="poub p2">{{ myHunt.clues[idclues].title_trash2 }}</div>
      <div class="poub p3">{{ myHunt.clues[idclues].title_trash3 }}</div>
    </div>
  </div>
</div>
