import { DOCUMENT } from "@angular/common";
import { Component, Inject, Input, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { HuntService } from "./services/hunt.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  constructor(private HuntService: HuntService) {
    // let instance: any = jsPlumb.getInstance({});
    // instance.setContainer("test-jeu");
    // console.log(instance);
    // instance.bind("ready", function () {
    //   instance.registerConnectionTypes({
    //     "red-connection": {
    //       paintStyle: { stroke: "red", strokeWidth: 5 },
    //       hoverPaintStyle: { stroke: "red", strokeWidth: 10 },
    //       connector: "Flowchart",
    //     },
    //   });
    //   instance.draggable("elem1", { containment: true });
    //   instance.draggable("elem2", { containment: true });
    //   instance.addEndpoint("elem1", {
    //     endpoint: "Dot", // rectangle, blank, image
    //     anchor: ["RightMiddle"],
    //     isSource: true,
    //     connectionType: "red-connection",
    //   });
    //   instance.addEndpoint("elem2", {
    //     endpoint: "Dot",
    //     anchor: ["LeftMiddle"],
    //     isTarget: true,
    //     connectionType: "red-connection",
    //   });
    // });
  }
}
