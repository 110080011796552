<div *ngIf="myHunt.clues[idclues].type === 'date'">
  <p
    *ngIf="HuntService.utilisateur.position == idclues"
    class="txt-secondary"
    [innerHTML]="myHunt.clues[idclues].content"
  ></p>
  <input
    class="reponse-txt"
    *ngIf="
      HuntService.utilisateur.position == idclues &&
      myHunt.clues[idclues].date_format == 'DD/MM/YYYY'
    "
    type="number"
    inputmode="decimal"
    name="days"
    id="days"
    min="1"
    max="31"
    step="1"
    [(ngModel)]="HuntService.utilisateur.reponseQuestionDate[0]"
    placeholder="Jour"
  />
  <input
    class="reponse-txt"
    *ngIf="
      HuntService.utilisateur.position == idclues &&
      myHunt.clues[idclues].date_format == 'DD/MM/YYYY'
    "
    type="number"
    inputmode="decimal"
    name="month"
    id="month"
    min="1"
    max="12"
    step="1"
    [(ngModel)]="HuntService.utilisateur.reponseQuestionDate[1]"
    placeholder="Mois"
  />
  <input
    class="reponse-txt"
    *ngIf="HuntService.utilisateur.position == idclues"
    type="number"
    inputmode="decimal"
    [min]="myHunt.clues[idclues].min_year"
    [max]="myHunt.clues[idclues].max_year"
    step="1"
    [(ngModel)]="HuntService.utilisateur.reponseQuestionDate[2]"
    placeholder="Année"
  />
  <p
    class="error-message"
    *ngIf="
      errorMessage && HuntService.utilisateur.position == idclues ? true : false
    "
  >
    {{ errorMessage }}
  </p>
  <button
    *ngIf="
      HuntService.utilisateur.position == idclues &&
      myHunt.clues[idclues].date_format == 'YYYY'
    "
    class="button-totemus"
    [disabled]="
      HuntService.utilisateur.reponseQuestionDate[2] > 0 ? false : true
    "
    (click)="
      checkDate(myHunt.clues[idclues].date, myHunt.clues[idclues].date_format)
    "
  >
    valider
  </button>
  <!-- checkDate(myHunt.clues[idclues].date,myHunt.clues[idclues].date_format) -->
  <button
    *ngIf="
      HuntService.utilisateur.position == idclues &&
      myHunt.clues[idclues].date_format == 'DD/MM/YYYY'
    "
    class="button-totemus"
    [disabled]="
      HuntService.utilisateur.reponseQuestionDate[0] &&
      HuntService.utilisateur.reponseQuestionDate[1] &&
      HuntService.utilisateur.reponseQuestionDate[2]
        ? false
        : true
    "
    (click)="
      checkDate(myHunt.clues[idclues].date, myHunt.clues[idclues].date_format)
    "
  >
    valider
  </button>
  <p
    class="indice"
    *ngIf="
      myHunt.clues[idclues].help[0] &&
      HuntService.utilisateur.position == idclues
    "
    [innerHTML]="myHunt.clues[idclues].help[0].label"
    (click)="showIndice()"
  ></p>
  <!-- showIndiceFunction() -->
  <div *ngIf="HuntService.utilisateur.position > idclues">
    <p class="txt-success">{{ myHunt.clues[idclues].date }}</p>
    <p
      class="txt-secondary"
      [innerHTML]="HuntService.chasse.clues[idclues].unlock_content"
    ></p>
    <div
      class="symbol-box"
      *ngIf="myHunt.clues[idclues].has_symbol == true"
      [style.backgroundColor]="myHunt.clues[idclues].symbol.color"
    >
      <img
        [src]="myHunt.clues[idclues].symbol.image.s160"
        alt="symbole débloqué"
      />
    </div>
    <button class="button-totemus" (click)="showNextStep()">
      Prochaine étape
    </button>
    <!-- nextStep() -->
  </div>
</div>
