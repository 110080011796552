import { Component, Input, OnInit } from "@angular/core";
import { HuntService } from "../services/hunt.service";
import { ApiHuntService } from "../services/api-hunt.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { fabric } from "fabric";
// import { jsPlumb } from "jsplumb";

declare var jquery: any;
declare var $: any;

@Component({
  selector: "app-hunt",
  templateUrl: "./hunt.component.html",
  styleUrls: ["./hunt.component.scss"],
})
export class HuntComponent implements OnInit {
  myHunt: any;
  _iOSDevice: any;
  showIOSinput: boolean = true;
  @Input() idclues!: number;
  errorMessage: any;
  showIndice: boolean = false;
  showQR: boolean = false;
  showParams: boolean = false;
  @Input() showRules: boolean = false;
  endForm: FormGroup;
  submitEndForm: boolean = false;
  showConcours: boolean = false;
  puzzleSolved: boolean = false;
  stillPlay: boolean = false;
  successAudio: any;
  errorAudio: any;
  notPlayedTime: number;
  autoScrollDisabled: boolean = false;
  docGameTab = [];
  docGameSolved = false;
  bodyEnd: any = {
    birth_date: "",
    phone: "",
    postcode: "",
    number_of_participants: "",
  };

  constructor(
    public HuntService: HuntService,
    public router: Router,
    public ApiHuntService: ApiHuntService,
    public fb: FormBuilder,
    private http: HttpClient
  ) {
    this.endForm = this.fb.group({
      postcode: [""],
      phone: [""],
      birth_date: [""],
      number_of_participant: [""],
      participation_id: [localStorage.getItem("participation_id")],
    });
  }

  ngOnInit(): void {
    //2heure = 7 200 000 millisec
    //1minute = 60 000 milli
    window.scrollTo(0, 0);
    let date = new Date();
    let dateForLocalstorage = date.getTime().toString();
    // parseInt(dateForLocalstorage)

    // FOR DEV ONLY
    localStorage.clear();
    //AUDIO
    this.successAudio = new Audio();
    this.successAudio.src = "../../assets/audio/success.mp3";
    this.errorAudio = new Audio();
    this.errorAudio.src = "../../assets/audio/error.mp3";
    //revenir en haut de page quand on reload
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
    //verification si on est sur iphone pour l'input de participation cadeau
    this._iOSDevice = !!navigator.platform.match(/iPhone|iPod|iPad/);
    this.idclues = 0;
    this.HuntService.utilisateur.position = 0;

    if (localStorage.getItem("utilisateur_position")) {
      this.showRules = false;
      let local = localStorage.getItem("utilisateur_position");
      let newPosition = parseInt(local);
      this.HuntService.utilisateur.position = newPosition;
      this.idclues = parseInt(local);
    }
    if (!localStorage.getItem("participation_id")) {
      // this.router.navigate(['/']);
    }
    //FOR DEV ONLY

    // for puzzle -> 14
    // for drag drop game -> 10
    // localStorage.clear();
    // this.idclues = 10;
    // this.HuntService.utilisateur.position = 10;
    // this.HuntService.utilisateur.endChasse = true;
    // this.showConcours=true;
    // this.submitEndForm = true;
    // console.log(localStorage.getItem("participation_id"));
    // console.log("position : "+localStorage.getItem("utilisateur_position"));

    //recuperation de la chasse via API
    this.ApiHuntService.getHunt().subscribe((result) => {
      this.myHunt = result;
      this.notPlayedTime = this.myHunt.inactivity_time * 3600000;
      //2heure = 7 200 000 millisec
      //1 heure = 3 600 000 milli
      //1minute = 60 000 milli
      console.log(this.notPlayedTime);
      if (this.HuntService.utilisateur.position >= this.myHunt.clues.length) {
        console.log("position" + this.HuntService.utilisateur.position);
        console.log("nb clues" + this.myHunt.clues.length);
        this.HuntService.utilisateur.endChasse = true;
      }
      if (localStorage.getItem("lastTime")) {
        let milliTime = parseInt(localStorage.getItem("lastTime"));
        let date = new Date();
        let dateForComparaison = date.getTime();

        console.log(dateForComparaison - milliTime);

        if (dateForComparaison - milliTime > this.notPlayedTime) {
          this.showStillPlay();
          localStorage.setItem("lastTime", dateForComparaison.toString());
        } else {
          localStorage.setItem("lastTime", dateForComparaison.toString());
        }
      } else {
        let dateForComparaison = date.getTime();
        localStorage.setItem("lastTime", dateForComparaison.toString());
      }
      //fonction qui permet de récuperer les symbols déjà passé lors d'une reconnexion
      if (localStorage.getItem("utilisateur_position")) {
        let local = localStorage.getItem("utilisateur_position");
        let newPosition = parseInt(local);
        for (let i = 0; i < newPosition; i++) {
          if (this.HuntService.chasse.clues[i].has_symbol == true) {
            this.HuntService.utilisateur.sac.push(
              this.HuntService.chasse.clues[i].symbol.image.s160
            );
            this.HuntService.utilisateur.sacColor.push(
              this.HuntService.chasse.clues[i].symbol.color
            );
          }
        }
      }
    });
    var canvas = new fabric.Canvas("c", { selection: false });
    console.log(canvas);

    var line, isDown;

    canvas.on("mouse:down", function (o) {
      console.log("down");
      isDown = true;
      var pointer = canvas.getPointer(o.e);
      var points = [pointer.x, pointer.y, pointer.x, pointer.y];
      line = new fabric.Line(points, {
        strokeWidth: 5,
        fill: "red",
        stroke: "red",
        originX: "center",
        originY: "center",
      });
      canvas.add(line);
    });

    canvas.on("mouse:move", function (o) {
      console.log("move");
      if (!isDown) return;
      var pointer = canvas.getPointer(o.e);
      line.set({ x2: pointer.x, y2: pointer.y });
      canvas.renderAll();
    });

    canvas.on("mouse:up", function (o) {
      isDown = false;
    });
  }
  ngAfterViewInit() {}

  endFormSubmit() {
    window.scrollTo(0, 0);
    console.log(this.bodyEnd.number_of_participants);

    this.http
      .put(
        "https://mediacite.noodev.be/wp-json/myhunts/v1/participations/" +
          localStorage.getItem("participation_id") +
          "/end",
        this.bodyEnd
      )
      .subscribe({
        next: (data) => {
          this.submitEndForm = true;
        },
        error: (error) => {
          console.log(error);
        },
      });
  }
  widthBar(chasse) {
    let newWidth = null;
    newWidth = (100 / (Object.keys(chasse).length - 1)) * this.idclues;
    return newWidth;
  }
  previousStep() {
    if (this.idclues == 0) {
      this.showRules = false;
    } else {
      window.scrollTo(0, 0);
      this.idclues--;
      this.HuntService.utilisateur.reponseQuestion = null;
      this.errorMessage = null;
      document.body.classList.remove("modal");
    }
  }
  nextStep() {
    if (this.idclues == 0 && this.showRules == false) {
      this.showRules = true;
    } else {
      window.scrollTo(0, 0);
      this.idclues++;
      this.HuntService.utilisateur.reponseQuestion = null;
      this.errorMessage = null;
      this.showQR = false;
    }
  }
  checkCode(userResponse, response) {
    if (response.length == 1) {
      if (userResponse == response[0].value) {
        userResponse = null;
        this.endEtape();
      } else {
        this.errorMessage = "Mauvaise réponse 🙁";
      }
    } else {
      //SI IL EXISTE PLUSEURS REPONSES
      var checkReponse = userResponse.toLowerCase();
      var goodResponse;
      response.forEach((element) => {
        if (checkReponse == element.value) {
          goodResponse = true;
        }
      });
      if (goodResponse === true) {
        userResponse = null;
        this.endEtape();
      } else {
        this.errorMessage = "Pas la bonne réponse 🙁";
      }
    }
  }
  onCodeResult(resultString: string, url: string) {
    let QRurl = "https://www.ludifica.be/code=" + url;
    // let old-QRurl = "https://totemus.be/?code="+url
    // let old-QRurl = "https://mediaciteapp.noodev.be/#/?code="+url
    console.log(QRurl);
    if (resultString == QRurl) {
      scroll(0, 0);
      // this.checkSymbole();
      this.errorMessage = null;
      this.idclues++;
      this.showQR = false;
      this.HuntService.utilisateur.position++;
      localStorage.setItem(
        "utilisateur_position",
        this.HuntService.utilisateur.position.toString()
      );
      if (
        this.HuntService.utilisateur.position >=
        this.HuntService.chasse.clues.length
      ) {
        this.HuntService.utilisateur.endChasse = true;
        // this.http.put("https://mediacite.noodev.be/wp-json/myhunts/v1/participations/"+localStorage.getItem("participation_id") +"/end",this.bodyEnd).subscribe({
        //     next: data => {
        //       console.log("fin de la chasse");
        //     },
        //     error: error => {
        //     console.log(error);
        //     }
        //   })
      }
    } else {
      this.errorMessage = "Pas le bon code 🙁 ";
    }
  }
  checkSymbole() {
    if (this.HuntService.utilisateur.position == this.idclues) {
      if (this.HuntService.chasse.clues[this.idclues].symbol.image.s160) {
        this.HuntService.utilisateur.sac.push(
          this.HuntService.chasse.clues[this.idclues].symbol.image.s160
        );
        // this.HuntService.utilisateur.sacColor.push(this.HuntService.chasse.clues[this.idclues].symbol.color);
        console.log("add image");
      }
    }
  }
  endHunt() {
    localStorage.clear();
    this.HuntService.utilisateur.endChasse = false;
    this.HuntService.utilisateur.position = 0;
    this.HuntService.utilisateur.sac = [];
    this.HuntService.utilisateur.sacColor = [];
    this.idclues = 0;
    this.router.navigate(["/"]);
  }
  showHelp() {
    scroll(0, 0);
    this.showIndice = true;
  }
  showCode() {
    this.showQR = true;
  }
  showRule(e) {
    if (e == "solved") {
      window.scrollTo(0, 0);
      this.showRules = true;
    }
  }
  startPoint(e) {
    if (e == true) {
      if (this.HuntService.utilisateur.position == this.idclues) {
        window.scrollTo(0, 0);
        this.HuntService.utilisateur.position++;
        this.idclues++;
        if (this.HuntService.utilisateur.position >= this.myHunt.clues.length) {
          this.HuntService.utilisateur.endChasse = true;
          this.http
            .put(
              "https://mediacite.noodev.be/wp-json/myhunts/v1/participations/" +
                localStorage.getItem("participation_id") +
                "/end",
              this.bodyEnd
            )
            .subscribe({
              next: (data) => {
                console.log("fin de la chasse");
              },
              error: (error) => {
                console.log(error);
              },
            });
        }
        localStorage.setItem(
          "utilisateur_position",
          this.HuntService.utilisateur.position.toString()
        );
      } else {
        window.scrollTo(0, 0);
        this.idclues++;
        this.HuntService.utilisateur.reponseQuestion = null;
        this.errorMessage = null;
        this.showQR = false;
      }
    }
  }
  dontPuzzle() {
    this.endEtape();
  }
  endEtape() {
    scroll(0, 0);
    this.checkSymbole();
    this.showQR = false;
    this.HuntService.utilisateur.position++;
    localStorage.setItem(
      "utilisateur_position",
      this.HuntService.utilisateur.position.toString()
    );
    if (
      this.HuntService.utilisateur.position >=
      this.HuntService.chasse.clues.length
    ) {
      this.HuntService.utilisateur.endChasse = true;
      // this.http.put("https://mediacite.noodev.be/wp-json/myhunts/v1/participations/"+localStorage.getItem("participation_id") +"/end",this.bodyEnd).subscribe({
      //     next: data => {
      //       console.log("fin de la chasse");
      //     },
      //     error: error => {
      //     console.log(error);
      //     }
      //   })
    }
  }
  successSound() {
    this.successAudio.load();
    this.successAudio.play();
  }
  errorSound() {
    this.errorAudio.load();
    this.errorAudio.play();
  }
  isDateSolved(event) {
    if (event == "solved") {
      this.endEtape();
    }
  }
  isPuzzleSolved(event) {
    if (event == "solved") {
      this.endEtape();
    }
  }
  isAudioSolved(event) {
    if (event == "solved") {
      this.endEtape();
    }
  }
  isQcmSolved(event) {
    if (event == "solved") {
      this.endEtape();
    }
  }
  isFourchetteSolved(event) {
    if (event == "solved") {
      this.endEtape();
    }
  }
  isDragSolved(event) {
    if (event == "solved") {
      this.endEtape();
    }
  }
  isIndiceNeeded(event) {
    if (event == true) {
      this.showIndice = true;
      document.body.classList.add("modal");
    }
  }
  isNextNeeded(event) {
    if (event == true) {
      this.nextStep();
    }
  }
  remake() {
    this.showRules = false;
    this.showParams = false;
    localStorage.removeItem("utilisateur_position");
    document.body.classList.remove("modal");
    this.HuntService.utilisateur.endChasse = false;
    this.HuntService.utilisateur.position = 0;
    this.idclues = 0;
    this.HuntService.utilisateur.sac = [];
    this.HuntService.utilisateur.sacColor = [];
    this.router.navigate(["/hunt"]);
  }
  showParamsFunction() {
    this.showParams = true;
    document.body.classList.add("modal");
  }
  hideParams() {
    this.showParams = false;
    document.body.classList.remove("modal");
  }
  showIndiceFunction() {
    this.showIndice = true;
    document.body.classList.add("modal");
  }
  hideIndice() {
    this.showIndice = false;
    document.body.classList.remove("modal");
  }
  showStillPlay() {
    this.stillPlay = true;
    document.body.classList.add("modal");
  }
  hideStillPlay() {
    this.stillPlay = false;
    document.body.classList.remove("modal");
  }
  showBagFunction() {
    this.HuntService.utilisateur.showBag = true;
    document.body.classList.add("modal");
  }
  testdocument(event) {
    let axeY = event.source.getFreeDragPosition().y; // returns { x: 0, y: 0 }
    let axeX = event.source.getFreeDragPosition().x; // returns { x: 0, y: 0 }
    console.log("y : " + axeY);
    console.log("x : " + axeX);
    if (axeY > -345 && axeY < -330 && axeX > 54 && axeX < 68) {
      console.log("c'est gooooood");
      this.docGameTab[0] = true;
    } else {
      this.docGameTab[0] = false;
    }
    this.checkWinDocumentGame();
  }
  testdocument2(event) {
    let axeY = event.source.getFreeDragPosition().y; // returns { x: 0, y: 0 }
    let axeX = event.source.getFreeDragPosition().x; // returns { x: 0, y: 0 }
    console.log("y : " + axeY);
    console.log("x : " + axeX);
    if (axeY > -464 && axeY < -447 && axeX < -44 && axeX > -59) {
      console.log("c'est gooooood");
      this.docGameTab[1] = true;
    } else {
      this.docGameTab[1] = false;
    }
    this.checkWinDocumentGame();
  }
  testdocument3(event) {
    let axeY = event.source.getFreeDragPosition().y; // returns { x: 0, y: 0 }
    let axeX = event.source.getFreeDragPosition().x; // returns { x: 0, y: 0 }
    console.log(event.source);
    console.log("y : " + axeY);
    console.log("x : " + axeX);
    if (axeY > -380 && axeY < -366 && axeX > 49 && axeX < 63) {
      console.log("c'est gooooood");
      this.docGameTab[2] = true;
    } else {
      this.docGameTab[2] = false;
    }
    this.checkWinDocumentGame();
  }
  testdocument4(event) {
    let axeY = event.source.getFreeDragPosition().y; // returns { x: 0, y: 0 }
    let axeX = event.source.getFreeDragPosition().x; // returns { x: 0, y: 0 }
    console.log("y : " + axeY);
    console.log("x : " + axeX);
    if (axeY > -250 && axeY < -240 && axeX < -46 && axeX > -62) {
      console.log("c'est gooooood");
      this.docGameTab[3] = true;
    } else {
      this.docGameTab[3] = false;
    }
    this.checkWinDocumentGame();
  }
  testdocument5(event) {
    let axeY = event.source.getFreeDragPosition().y; // returns { x: 0, y: 0 }
    let axeX = event.source.getFreeDragPosition().x; // returns { x: 0, y: 0 }
    console.log("y : " + axeY);
    console.log("x : " + axeX);
    if (axeY > -151 && axeY < -136 && axeX > -52 && axeX < -38) {
      console.log("c'est gooooood");
      this.docGameTab[4] = true;
    } else {
      this.docGameTab[4] = false;
    }
    this.checkWinDocumentGame();
  }
  testdocument6(event) {
    let axeY = event.source.getFreeDragPosition().y; // returns { x: 0, y: 0 }
    let axeX = event.source.getFreeDragPosition().x; // returns { x: 0, y: 0 }
    console.log("y : " + axeY);
    console.log("x : " + axeX);
    if (axeY > -276 && axeY < -260 && axeX > 19 && axeX < 32) {
      console.log("c'est gooooood");
      this.docGameTab[5] = true;
    } else {
      this.docGameTab[5] = false;
    }
    this.checkWinDocumentGame();
  }

  checkWinDocumentGame() {
    if (
      this.docGameTab[0] === true &&
      this.docGameTab[1] === true &&
      this.docGameTab[2] === true &&
      this.docGameTab[3] === true &&
      this.docGameTab[4] === true &&
      this.docGameTab[5] === true
    ) {
      console.log("c'est la win");
      this.docGameSolved = true;
    }
  }
  rebus(reponse, goodResponse) {
    let checkReponse = reponse.toLowerCase();
    if (checkReponse === goodResponse) {
      console.log("c'est bon");
      window.scrollTo(0, 0);
      this.showConcours = true;
    }
  }

  // checkDate(Reponse,dateFormat){

  //   var dateAlt = Reponse.split('/');

  //   if(dateFormat == "YYYY"){

  //     if(this.HuntService.utilisateur.reponseQuestionDate[2].toString() === dateAlt[2]){
  //       this.HuntService.utilisateur.reponseQuestionDate = [];
  //       this.checkSymbole();
  //       this.HuntService.utilisateur.position++;
  //       if(this.HuntService.utilisateur.position>= this.myHunt.clues.length){
  //         this.HuntService.utilisateur.endChasse=true;
  //         this.http.put("https://mediacite.noodev.be/wp-json/myhunts/v1/participations/"+localStorage.getItem("participation_id") +"/end",this.bodyEnd).subscribe({
  //           next: data => {
  //             console.log("fin de la chasse");
  //           },
  //           error: error => {
  //           console.log(error);
  //           }
  //         })
  //       }
  //       localStorage.setItem("utilisateur_position",this.HuntService.utilisateur.position.toString());

  //     }else{
  //       this.errorMessage = "Pas la bonne date 🙁";
  //     }
  //   }
  //   if(dateFormat == "DD/MM/YYYY"){
  //     let day = null;
  //     let month = null;
  //     let year = this.HuntService.utilisateur.reponseQuestionDate[2].toString();

  //     if(this.HuntService.utilisateur.reponseQuestionDate[0]<10){
  //       day = "0"+this.HuntService.utilisateur.reponseQuestionDate[0].toString();
  //     }
  //     if(this.HuntService.utilisateur.reponseQuestionDate[1]<10){
  //       month = "0"+this.HuntService.utilisateur.reponseQuestionDate[1].toString();
  //     }

  //     if(day === dateAlt[0] && month === dateAlt[1] && year === dateAlt[2]){
  //       this.HuntService.utilisateur.reponseQuestionDate = [];
  //       this.endEtape()
  //     }else{
  //       this.errorMessage = "Pas la bonne date 🙁";
  //       console.error('pas bon');
  //     }
  //   }
  // }
}
